<template>
  <el-dialog
          width="500px"
          title="地图选点"
          :visible.sync="isDialog"
          append-to-body>
    <iframe width="100%" height="100%" style="border: none;width:100%;height: 400px;" :src="map_src"></iframe>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        map_src: 'https://mapapi.qq.com/web/mapComponents/locationPicker/v/index.html?search=1&type=1&key=GNCBZ-V4RLI-4WFGV-U5U5K-FLP67-HCBGJ&referer=location',
        map_data: {
          address: '',
          lng: '',
          lat: '',
          name: ''
        },
        isDialog: false, // 控制模态框
      }
    },
    created() {
      this.getInfo()
    },
    methods: {
      // 选择
      getInfo(){
        let that = this
        window.addEventListener('message', function(event) {
          var loc = event.data;
          if (loc && loc.module === 'locationPicker') {
            that.map_data.address = loc.poiaddress
            that.map_data.name = loc.poiname
            that.map_data.lat = loc.latlng.lat
            that.map_data.lng = loc.latlng.lng
            that.$emit('chooseOrgAddr', that.map_data)
          }
        }, false);
      },
      // 父组件调用方法,打开模态框
      openDialog() {
        this.isDialog = true
      },
      // 关闭模态框
      closeDialog() {
        this.isDialog = false
      }
    }
  }
</script>
