import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/renthouse/rhUserCode/page', data, {
    trim: true
  })
}


// 查询
export function invitationListOneself (data) {
  return request.post('/renthouse/rhUserCode/invitationListOneself', data, {
    trim: true
  })
}
