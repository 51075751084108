<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="状态(0 已预约，1 服务中,2 待支付,3 已完成,4 已取消)" prop="status">
        <el-input v-model="form.status" placeholder="请输入状态(0 已预约，1 服务中,2 待支付,3 已完成,4 已取消)" v-trim/>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  export default {
    name: 'OperaRhAppointShotWindow',
    extends: BaseOpera,
    components: { GlobalWindow },
    data () {
      return {
        // 表单数据
        form: {
          id: null,
          status: ''
        },
        // 验证规则
        rules: {
        }
      }
    },
    created () {
      this.config({
        api: '/rentHouse/rhAppointShot',
        'field.id': 'id'
      })
    }
  }
</script>
