<template>
  <TableLayout :permissions="['system:job:snippet:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="定时任务" prop="jobId">
        <JobSelect v-model="searchForm.jobId" clearable @change="search"/>
      </el-form-item>
      <el-form-item label="分片编号" prop="snippetCode">
        <el-input v-model="searchForm.snippetCode" placeholder="请输入分片编号" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="分发组" prop="snippetCode">
        <el-input v-model="searchForm.jobDistributeGroup" placeholder="请输入分发组" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['system:job:snippet:delete']">
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['system:job:snippet:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        :default-sort="{prop: 'createTime', order: 'descending'}"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="jobName" label="JOB名称" min-width="100px"></el-table-column>
        <el-table-column prop="snippetCode" label="分片编号" min-width="260px"></el-table-column>
        <el-table-column prop="jobDistributeGroup" label="分发组" min-width="260px"></el-table-column>
        <el-table-column prop="jobBusinessTime" label="任务执行业务时间" min-width="140px"></el-table-column>
        <el-table-column prop="handler" label="分片处理器类" min-width="160px"></el-table-column>
        <el-table-column prop="snippetDataSize" label="分片数据数量" min-width="100px"></el-table-column>
        <el-table-column prop="allowServerIps" label="服务器白名单" min-width="140px">
          <template slot-scope="{ row }">{{row.allowServerIps == null || row.allowServerIps === '' ? '不限制' : row.allowServerIps}}</template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{ row }">
            {{ row.status | statusText }}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="140px" sortable="custom" sort-by="CREATE_TIME"></el-table-column>
        <el-table-column
          v-if="containPermissions(['system:job:snippet:update', 'system:job:snippet:delete'])"
          label="操作"
          min-width="140"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button v-if="row.status === 0" type="text" @click="trigger(row)" icon="el-icon-delete">执行</el-button>
            <el-button v-if="row.status === -2" type="text" @click="trigger(row)" icon="el-icon-delete">重新执行</el-button>
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['system:job:snippet:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import JobSelect from '@/components/system/job/JobSelect'
export default {
  name: 'Snippet',
  extends: BaseTable,
  components: { JobSelect, TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        jobId: '',
        jobDistributeGroup: '',
        snippetCode: ''
      }
    }
  },
  filters: {
    // 状态
    statusText (value) {
      // （0准备，1运行中，2已完成）
      if (value === 0) {
        return '准备'
      }
      if (value === 1) {
        return '运行中'
      }
      if (value === 2) {
        return '执行成功'
      }
      if (value === -2) {
        return '执行失败'
      }
      return '未知'
    }
  },
  methods: {
    /**
     * 执行任务
     *
     * @param row 行对象
     */
    trigger (row) {
      this.$dialog.confirm(`确认执行【${row.snippetCode}】分片任务吗？`, '提示', {
        confirmButtonText: '立即执行',
        type: 'warning'
      })
        .then(() => {
          this.api.trigger({ id: row.id })
            .then(() => {
              this.$tip.apiSuccess('分片任务已启动，如任务启用了日志，可在任务日志中查看执行进度。')
              this.handlePageChange()
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
        })
    }
  },
  created () {
    this.config({
      module: '定时任务分片',
      api: '/system/snippet',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }],
      'field.id': 'id',
      'field.main': 'snippetCode'
    })
    this.search()
  }
}
</script>
