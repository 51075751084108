<template>
  <TableLayout :permissions="['system:job:log:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="定时任务" prop="jobId">
        <JobSelect v-model="searchForm.jobId" clearable @change="search"/>
      </el-form-item>
      <el-form-item label="分片编码" prop="snippetCode">
        <el-input v-model="searchForm.snippetCode" placeholder="请输入分片编码" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="分发组" prop="snippetCode">
        <el-input v-model="searchForm.jobDistributeGroup" placeholder="请输入分发组" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="业务时间" prop="businessTime">
        <el-date-picker v-model="searchForm.businessTime" value-format="yyyy-MM-dd" placeholder="请输入业务时间戳" @change="search"/>
      </el-form-item>
      <el-form-item label="执行服务器IP" prop="serverIp">
        <el-input v-model="searchForm.serverIp" placeholder="请输入执行服务器IP" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <el-form-item label="触发类型" prop="triggerType">
        <el-select v-model="searchForm.triggerType" placeholder="请选择触发类型" clearable @change="search">
          <el-option value="SYSTEM" label="系统定时触发"/>
          <el-option value="MANUAL" label="手动触发"/>
        </el-select>
      </el-form-item>
      <el-form-item label="状态" prop="triggerType">
        <el-select v-model="searchForm.status" placeholder="请选择状态" clearable @change="search">
          <el-option value="-1" label="执行中"/>
          <el-option value="1" label="执行成功"/>
          <el-option value="0" label="执行失败"/>
          <el-option value="2" label="跳过"/>
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间" prop="createTime">
        <el-date-picker v-model="searchForm.createTime" value-format="yyyy-MM-dd" placeholder="请输入日志创建时间" @change="search"/>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <div slot="space" class="status-bar">
      <label class="status-ing">执行中</label>
      <label class="status-failed">执行失败</label>
      <label class="status-ignore">跳过</label>
    </div>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['system:job:log:delete']">
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['system:job:log:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        :default-sort="{prop: 'createTime', order: 'descending'}"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="jobName" label="任务名称" min-width="100px"></el-table-column>
        <el-table-column prop="snippetCode" label="分片编码" min-width="260px"></el-table-column>
        <el-table-column prop="jobDistributeGroup" label="分发组" min-width="260px"></el-table-column>
        <el-table-column prop="businessTime" label="业务时间" min-width="140px"></el-table-column>
        <el-table-column prop="runTimeStart" label="执行开始时间" min-width="140px"></el-table-column>
        <el-table-column prop="runTimeEnd" label="执行结束时间" min-width="140px"></el-table-column>
        <el-table-column prop="runSpendTime" label="耗时(ms)" min-width="100px"></el-table-column>
        <el-table-column prop="serverIp" label="执行服务器IP" min-width="140px"></el-table-column>
        <el-table-column prop="triggerType" label="触发类型" min-width="100px">
          <template slot-scope="{ row }">
            {{ row.triggerType | triggerTypeText }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{row}">{{ row.status | logStatusText }}</template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="200px">
          <ColumnDetail slot-scope="{ row }" :content="row.remark" :limit="16"/>
        </el-table-column>
        <el-table-column prop="handleTotalSize" label="处理总数" min-width="100px"></el-table-column>
        <el-table-column prop="handleSuccessSize" label="处理成功数" min-width="100px"></el-table-column>
        <el-table-column prop="context" label="执行上下文" min-width="200px">
          <ColumnDetail slot-scope="{ row }" :content="row.context" :limit="16"/>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="140px" sortable="custom" sort-by="CREATE_TIME"></el-table-column>
        <el-table-column
          v-if="containPermissions(['system:job:log:update', 'system:job:log:delete'])"
          label="操作"
          min-width="120"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['system:job:log:delete']">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import ColumnDetail from '@/components/common/ColumnDetail'
import JobSelect from '@/components/system/job/JobSelect'
export default {
  name: 'SystemJobLog',
  extends: BaseTable,
  components: { JobSelect, ColumnDetail, TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        jobId: '',
        snippetCode: '',
        jobDistributeGroup: '',
        businessTime: '',
        serverIp: '',
        triggerType: '',
        status: '',
        createTime: ''
      }
    }
  },
  methods: {
    /**
     * 标记行class
     *
     * @param row 行对象
     * @returns {string}
     */
    tableRowClassName ({ row }) {
      // 执行中
      if (row.status === -1) {
        return 'ing-log'
      }
      // 失败
      if (row.status === 0) {
        return 'failed-log'
      }
      // 跳过
      if (row.status === 2) {
        return 'ignore-log'
      }
      return ''
    }
  },
  filters: {
    // 触发类型
    triggerTypeText (value) {
      if (value === 'SYSTEM') {
        return '系统定时触发'
      }
      if (value === 'MANUAL') {
        return '手动触发'
      }
      return '未知'
    },
    // 状态
    logStatusText (value) {
      if (value === -1) {
        return '执行中'
      }
      if (value === 0) {
        return '执行失败'
      }
      if (value === 1) {
        return '执行成功'
      }
      if (value === 2) {
        return '跳过'
      }
      return '未知'
    }
  },
  created () {
    this.config({
      module: '系统定时任务日志',
      api: '/system/jobLog',
      sorts: [{
        property: 'CREATE_TIME',
        direction: 'DESC'
      }],
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
<style lang="scss" scoped>
  // 状态栏
  .status-bar {
    padding: 0 16px;
    [class^=status-] {
      font-size: 13px;
      margin-right: 12px;
      line-height: 40px;
      &::before {
        position: relative;
        top: 2px;
        display: inline-block;
        content: '';
        width: 12px;
        height: 12px;
        border: 1px solid #ccc;
        background: #fff;
        margin-right: 6px;
      }
    }
    .status-ing::before {
      background-color: #f0f9eb;
      border-color: #67c23a;
    }
    .status-ignore::before {
      background-color: #eee;
      border-color: #ccc;
    }
    .status-failed::before {
      background-color: #fdf0f0;
      border-color: indianred;
    }
  }
  /deep/ .table-content {
    margin-top: 0;
  }
  // 执行中
  /deep/ .ing-log td {
    background-color: #f0f9eb !important;
  }
  // 忽略
  /deep/ .ignore-log td {
    background-color: #eee !important;
  }
  // 失败
  /deep/ .failed-log td {
    background-color: #fdf0f0 !important;
  }
</style>
