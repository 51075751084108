<template>
    <GlobalWindow
        :title="title"
        width="600px"
        :visible.sync="visible"
        :confirm-working="isWorking"
        @confirm="confirm"
    >
        <div class="list">
            <div class="list_item">
                <div class="list_item_label">我是：</div>
                <div class="list_item_val" v-if="form.rentType === 0">业主</div>
                <div class="list_item_val" v-if="form.rentType === 1">室友</div>
                <div class="list_item_val" v-if="form.rentType === 2">转租</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源标题：</div>
                <div class="list_item_val">{{form.title}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">是否可短租：</div>
                <div class="list_item_val" v-if="form.enableShortRent === 0">否</div>
                <div class="list_item_val" v-if="form.enableShortRent === 1">是</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">小区位置：</div>
                <div class="list_item_val">{{form.address}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">租房类型：</div>
                <div class="list_item_val">{{form.houseType}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">装修情况：</div>
                <div class="list_item_val" v-if="form.decorationLevel === 0">精装</div>
                <div class="list_item_val" v-if="form.decorationLevel === 1">简装</div>
                <div class="list_item_val" v-if="form.decorationLevel === 2">豪装</div>
                <div class="list_item_val" v-if="form.decorationLevel === 3">毛坯</div>
                <div class="list_item_val" v-if="form.decorationLevel === 4">不限</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">出租方式：</div>
                <div class="list_item_val">{{form.rentalFormName}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">户型：</div>
                <div class="list_item_val">{{form.bedroomNum}}房{{form.saloonNum}}厅{{form.toiletNum}}卫</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">面积：</div>
                <div class="list_item_val">{{form.area}}㎡</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">是否可售：</div>
                <div class="list_item_val">{{form.enableSell === 1 ? '是' : '否'}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">租金：</div>
                <div class="list_item_val">{{form.price}}元</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">入住时间：</div>
                <div class="list_item_val">{{form.inTime ? form.inTime : '随时入住'}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">楼层：</div>
                <div class="list_item_val">{{form.inFloorNum}}层 共{{form.floorNum}}层 {{form.liftNum}}电梯</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">是否有地铁：</div>
                <div class="list_item_val">{{form.haveMetro === 1 ? '是' : '否'}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源特色：</div>
                <div class="list_item_val">{{form.description}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">图片：</div>
                <div class="list_item_list" v-if="form.pic">
                    <div class="list_item_list_image" v-for="(item, index) in form.pic.split(',')" :key="index">
                        <img :src="baseUrl + item" />
                    </div>
                </div>
            </div>
            <div class="list_item">
                <div class="list_item_label">视频：</div>
                <div class="list_item_list" v-if="form.video">
                    <video controls :src="baseUrl + form.video"></video>
                </div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源有效期：</div>
                <div class="list_item_val">{{form.displayMonth}}个月</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源标签：</div>
                <div class="list_item_list" v-if="form.label">
                    <el-tag style="margin-right: 15px;margin-bottom: 10px;" v-for="(item, index) in form.label.split(',')" :key="index">{{item}}</el-tag>
                </div>
            </div>
            <div class="list_item">
                <div class="list_item_label">联系方式：</div>
                <div class="list_item_val" v-if="form.phone">手机号</div>
                <div class="list_item_val" v-else-if="form.wx">微信号</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">手机号：</div>
                <div class="list_item_val">{{form.phone}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">微信号：</div>
                <div class="list_item_val">{{form.wx}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">公开联系方式：</div>
                <div class="list_item_val">{{form.publicContact === 1 ? '公开' : '不公开'}}</div>
            </div>
            <div class="list_item" style="display: flex; align-items: center; justify-content: center;" v-if="form.seeType !== 'desc'">
                <el-button type="success" :loading="isWorking" @click="examine(1)">通过</el-button>
                <el-button type="danger" :loading="isWorking" @click="examine(2)">不通过</el-button>
            </div>
        </div>
    </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
export default {
  name: 'OperaRhRentInfoWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      // 表单数据
      form: {
        id: null,
        rentType: '',
        title: '',
        enableShortRent: '',
        address: '',
        houseType: '',
        decorationLevel: '',
        rentalFormName: '',
        bedroomNum: '',
        saloonNum: '',
        toiletNum: '',
        area: '',
        enableSell: '',
        inTime: '',
        inFloorNum: '',
        floorNum: '',
        liftNum: '',
        haveMetro: '',
        description: '',
        pic: '',
        video: '',
        displayMonth: '',
        label: '',
        price: '',
        mobile: '',
        phone: '',
        wx: '',
        publicContact: '',
        seeType: ''
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/rhRentInfo',
      'field.id': 'id'
    })
  }
}
</script>

<style lang="scss" scoped>
    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        .list_item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
            .list_item_label {
                width: 120px;
                flex-shrink: 0;
                font-size: 16px;
                color: #000000;
                text-align: right;
            }
            .list_item_list {
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                video {
                    width: 100%;
                    height: 200px;
                }
                .list_item_list_image {
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    margin-left: 15px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .list_item_val {
                flex: 1;
                font-size: 16px;
                color: #000000;
            }
        }
    }
</style>
