import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/system/job/log/page', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/system/job/log/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/system/job/log/delete/batch', {
    params: {
      ids
    }
  })
}
