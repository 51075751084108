<template>
    <TableLayout :permissions="['renthouse:rhchatlog:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
            <el-form-item label="发起方" prop="startUser">
                <el-input v-model="searchForm.startUser" placeholder="请输入发起方" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <el-form-item label="接收人" prop="targetUser">
                <el-input v-model="searchForm.targetUser" placeholder="请输入接收人" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
            >
                <el-table-column prop="chatId" label="聊天室id" min-width="100px"></el-table-column>
                <el-table-column prop="startUserName" label="发起方" min-width="100px"></el-table-column>
                <el-table-column prop="targetUserName" label="接收人" min-width="100px"></el-table-column>
                <el-table-column prop="content" label="聊天内容" min-width="100px"></el-table-column>
                <el-table-column label="类型" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.relatedType === 0">位置信息</span>
                        <span v-else-if="row.relatedType === 1">房源信息</span>
                        <span v-else-if="row.relatedType === 2">合同信息</span>
                        <span v-else-if="row.relatedType === 3">收款码</span>
                        <span v-else-if="row.relatedType === 4">系统消息</span>
                        <span v-else>普通聊天信息</span>
                    </template>
                </el-table-column>
                <el-table-column prop="positionJson" label="信息" min-width="100px"></el-table-column>
                <el-table-column label="关联种类" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.relatedType === 1">房源信息</span>
                        <span v-if="row.relatedType === 0">位置信息</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="发送时间" min-width="100px"></el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
    </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
export default {
  name: 'RhChatLog',
  extends: BaseTable,
  components: { TableLayout, Pagination },
  data () {
    return {
      // 搜索
      searchForm: {
        startUser: '',
        targetUser: ''
      }
    }
  },
  created () {
    this.config({
      module: '聊天室信息记录',
      api: '/rentHouse/rhChatLog',
      'field.id': 'id',
      'field.main': 'id',
        sorts: [{
            property: 'CREATE_TIME',
            direction: 'DESC'
        }]
    })
    this.search()
  }
}
</script>
