<template>
  <TableLayout v-permissions="['system:job:query']">
    <!-- 搜索表单 -->
    <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
      <el-form-item label="任务名称" prop="jobName">
        <el-input v-model="searchForm.jobName" placeholder="任务名称" @keypress.enter.native="search"></el-input>
      </el-form-item>
      <section>
        <el-button type="primary" @click="search">搜索</el-button>
        <el-button @click="reset">重置</el-button>
      </section>
    </el-form>
    <!-- 表格和分页 -->
    <template v-slot:table-wrap>
      <ul class="toolbar" v-permissions="['system:job:create', 'system:job:delete']">
        <li><el-button type="primary" @click="$refs.operaJobWindow.open('新建定时任务列表')" icon="el-icon-plus" v-permissions="['system:job:create']">新建</el-button></li>
        <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['system:job:delete']">删除</el-button></li>
      </ul>
      <el-table
        v-loading="isWorking.search"
        :data="tableData.list"
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" fixed="left"></el-table-column>
        <el-table-column prop="jobName" label="任务名称" min-width="100px" fixed="left"></el-table-column>
        <el-table-column prop="jobDescription" label="任务描述" min-width="100px"></el-table-column>
        <el-table-column prop="handler" label="任务处理器" min-width="160px"></el-table-column>
        <el-table-column prop="cronExpression" label="CRON表达式" min-width="140px"></el-table-column>
        <el-table-column prop="nextFireTime" label="下次执行时间" min-width="140px"></el-table-column>
        <el-table-column prop="distributeHandler" label="数据分发处理器" min-width="160px"></el-table-column>
        <el-table-column prop="distributeLimit" label="分发数限制" min-width="100px"></el-table-column>
        <el-table-column prop="disabled" label="是否启用" min-width="100px">
          <template slot-scope="{row}">
            <el-switch v-model="row.disabled" :active-value="false" :inactive-value="true" @change="switchDisabled(row)"/>
          </template>
        </el-table-column>
        <el-table-column prop="withAsync" label="异步执行" min-width="100px">
          <template slot-scope="{ row }">{{row.withAsync | enableText}}</template>
        </el-table-column>
        <el-table-column prop="disallowConcurrent" label="并发执行" min-width="100px">
          <template slot-scope="{ row }">{{row.disallowConcurrent | disabledText}}</template>
        </el-table-column>
        <el-table-column prop="withLog" label="记录日志" min-width="100px">
          <template slot-scope="{ row }">{{row.withLog | enableText}}</template>
        </el-table-column>
        <el-table-column prop="allowServerIps" label="服务器白名单" min-width="140px">
          <template slot-scope="{ row }">{{row.allowServerIps == null || row.allowServerIps === '' ? '不限制' : row.allowServerIps}}</template>
        </el-table-column>
        <el-table-column prop="status" label="状态" min-width="100px">
          <template slot-scope="{ row }">
            {{ row.status | statusText }}
          </template>
        </el-table-column>
        <el-table-column prop="createUserInfo.realname" label="创建人" min-width="100px"></el-table-column>
        <el-table-column prop="createTime" label="创建时间" min-width="140px"></el-table-column>
        <el-table-column prop="updateUserInfo.realname" label="更新人" min-width="100px"></el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="140px"></el-table-column>
        <el-table-column
          v-if="containPermissions(['system:job:update', 'system:job:delete'])"
          label="操作"
          min-width="160"
          fixed="right"
        >
          <template slot-scope="{row}">
            <el-button v-if="!row.disabled && row.status !== 1" type="text" icon="el-icon-mouse" @click="$refs.triggerJobWindow.open(row)">执行</el-button>
            <el-button type="text" icon="el-icon-edit" v-permissions="['system:job:update']" @click="$refs.operaJobWindow.open('编辑定时任务', row)">编辑</el-button>
            <el-button type="text" icon="el-icon-delete" v-permissions="['system:job:delete']" @click="deleteById(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :pagination="tableData.pagination"
      >
      </pagination>
    </template>
    <!-- 新建/修改 -->
    <OperaJobWindow ref="operaJobWindow" @success="handlePageChange"/>
    <!-- 触发任务 -->
    <TriggerJobWindow ref="triggerJobWindow"/>
  </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaJobWindow from '@/components/system/job/OperaJobWindow'
import TriggerJobWindow from '@/components/system/job/TriggerJobWindow'
export default {
  name: 'Job',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaJobWindow, TriggerJobWindow },
  data () {
    return {
      // 搜索
      searchForm: {
        jobName: ''
      }
    }
  },
  filters: {
    // 状态
    statusText (value) {
      if (value === 0) {
        return '准备'
      }
      if (value === 1) {
        return '运行中'
      }
      return '未知'
    }
  },
  methods: {
    /**
     * 覆盖页码变更处理
     *
     * @param pageIndex 页码
     */
    handlePageChange (pageIndex) {
      this.__checkApi()
      this.tableData.pagination.pageIndex = pageIndex || this.tableData.pagination.pageIndex
      this.isWorking.search = true
      this.api.fetchList({
        page: this.tableData.pagination.pageIndex,
        capacity: this.tableData.pagination.pageSize,
        model: this.searchForm,
        sorts: this.tableData.sorts
      })
        .then(data => {
          this.tableData.list = data.records
          this.tableData.pagination.total = data.total
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking.search = false
        })
    },
    /**
     * 启用/禁用
     *
     * @param row 行对象
     */
    switchDisabled (row) {
      const newValue = row.disabled
      row.disabled = !row.disabled
      // 开启
      if (!newValue) {
        this.__updateStatus(row, newValue)
        return
      }
      // 禁用
      this.$dialog.disableConfirm(`确认禁用 ${row.jobName} 任务吗？`)
        .then(() => {
          this.__updateStatus(row, newValue)
        })
        .catch(() => {
        })
    },
    /**
     * 修改任务状态
     *
     * @param row 行对象
     * @param newValue 新值
     * @private
     */
    __updateStatus (row, newValue) {
      this.api.updateStatus({
        id: row.id,
        disabled: newValue,
        handler: row.handler
      })
        .then(() => {
          row.disabled = newValue
          setTimeout(() => {
            this.$dialog.attentionConfirm((newValue ? '禁用' : '启用') + '成功，需重启服务后生效')
          }, 300)
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
    },
    /**
     * 覆盖删除后处理，在单行删除或多行删除后调用
     * @private
     */
    __afterDelete (deleteCount = 1) {
      setTimeout(() => {
        this.$dialog.attentionConfirm('删除成功，需重启服务后生效')
      }, 300)
      // 删除当前页最后一条记录时查询上一页数据
      if (this.tableData.list.length - deleteCount === 0) {
        this.handlePageChange(this.tableData.pagination.pageIndex - 1 === 0 ? 1 : this.tableData.pagination.pageIndex - 1)
      } else {
        this.handlePageChange(this.tableData.pagination.pageIndex)
      }
    }
  },
  created () {
    this.config({
      module: '定时任务列表',
      api: '/system/job',
      'field.id': 'id',
      'field.main': 'jobName'
    })
    this.search()
  }
}
</script>
