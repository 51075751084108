<template>
  <el-select
    class="job-select"
    :class="{select__block: !inline}"
    :value="value"
    :placeholder="placeholder"
    :clearable="clearable"
    :disabled="disabled"
    @change="$emit('change', $event)"
    @input="$emit('input', $event)"
  >
    <el-option v-for="job in jobs" :key="job.id" :value="job.id" :label="job.jobName"/>
  </el-select>
</template>

<script>
import { fetchAll } from '@/api/system/job'
export default {
  name: 'JobSelect',
  props: {
    value: {},
    placeholder: {
      default: '请选择定时任务'
    },
    inline: {
      default: true
    },
    disabled: {},
    clearable: {
      default: false
    }
  },
  data () {
    return {
      jobs: []
    }
  },
  created () {
    fetchAll()
      .then(data => {
        this.jobs = data
      })
  }
}
</script>
<style lang="scss" scoped>
.select__block {
  display: block;
}
</style>
