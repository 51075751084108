<template>
    <TableLayout :permissions="['renthouse:rhhouse:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
            <el-form-item label="出租类型" prop="rentType">
                <el-select v-model="searchForm.rentType" placeholder="请选择" @keypress.enter.native="search">
                    <el-option label="业主" :value="0"></el-option>
                    <el-option label="室友" :value="1"></el-option>
                    <el-option label="转租" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="房源类型" prop="enableSell">
                <el-select v-model="searchForm.enableSell" placeholder="请选择" @keypress.enter.native="search">
                    <el-option label="出租" :value="0"></el-option>
                    <el-option label="出售" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="手机号码" prop="phone">
                <el-input type="number" v-model="searchForm.phone" placeholder="请输入手机号码" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <el-form-item label="房源状态" prop="status">
                <el-select v-model="searchForm.status" placeholder="请选择" @keypress.enter.native="search">
                    <el-option label="下架" :value="0"></el-option>
                    <el-option label="上架" :value="1"></el-option>
                </el-select>
            </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <ul class="toolbar" v-permissions="['renthouse:rhhouse:create', 'renthouse:rhhouse:delete']">
                <li><el-button type="primary" @click="$refs.PublishListings.open('发布房源')" v-permissions="['renthouse:rhhouse:create']">发布房源</el-button></li>
                <li><el-button type="primary" @click="importHouse">导入房源</el-button></li>
                <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhhouse:delete']">删除</el-button></li>
            </ul>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55" fixed="left"></el-table-column>
                <el-table-column prop="cityName" label="城市" min-width="90px"></el-table-column>
                <el-table-column label="房源标题" min-width="200px" show-overflow-tooltip>
                    <template slot-scope="{row}">
                        {{row.title}}{{row.buildingNum}}{{row.buildingNumUnit}}{{row.roomNum}}{{row.roomNumUnit}}
                    </template>
                </el-table-column>
                <el-table-column label="出租类型" min-width="100px">
                    <template slot-scope="{row}">
                        <template v-if="row.enableSell === 0">
                            <span v-if="row.rentType === 0">业主</span>
                            <span v-if="row.rentType === 1">室友</span>
                            <span v-if="row.rentType === 2">转租</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="房源类型" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.enableSell === 0">出租</span>
                        <span v-if="row.enableSell === 1">出售</span>
                    </template>
                </el-table-column>
                <el-table-column label="房屋类型" min-width="100px">
                    <template slot-scope="{row}">
                        <template v-if="row.enableSell === 0">
                            <span v-if="row.houseType === 117">住宅</span>
                            <span v-if="row.houseType === 141">公寓</span>
                            <span v-if="row.houseType === 103">写字楼</span>
                            <span v-if="row.houseType === 142">商铺</span>
                        </template>
                        <template v-if="row.enableSell === 1">
                            <span v-if="row.houseType === 117">住宅</span>
                            <span v-if="row.houseType === 141">公寓</span>
                            <span v-if="row.houseType === 103">写字楼</span>
                            <span v-if="row.houseType === 142">商铺</span>
                            <span v-if="row.houseType === 116">别墅</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="出租方式" min-width="100px">
                    <template slot-scope="{row}">
                        <template v-if="(row.enableSell === 1 || row.enableSell === 0) && row.houseType === 142">
                            <span v-if="row.rentalForm === 0">临街门面</span>
                            <span v-if="row.rentalForm === 1">商场门面</span>
                        </template>
                        <template v-else-if="row.enableSell === 0 && row.houseType !== 142">
                            <span v-if="row.rentalForm === 0">整租</span>
                            <span v-if="row.rentalForm === 1">单间</span>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="审核状态" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.reviewStatus === 0">审核中</span>
                        <span style="color: green;" v-if="row.reviewStatus === 1">已通过</span>
                        <span style="color: red;" v-if="row.reviewStatus === 2">未通过</span>
                    </template>
                </el-table-column>
                <el-table-column label="房源状态" min-width="100px">
                    <template slot-scope="{row}">
                        <span style="color: red;" v-if="row.status === 0">下架</span>
                        <span style="color: green;" v-if="row.status === 1">上架</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createUserName" label="发布人" min-width="100px"></el-table-column>
                <el-table-column prop="createTime" label="发布时间" min-width="180px"></el-table-column>
                <el-table-column
                    label="操作"
                    min-width="200"
                    fixed="right"
                >
                    <template slot-scope="{row}">
                        <el-button type="text" @click="offShelf(row.id, row.status)" v-if="row.reviewStatus === 1">{{row.status === 0 ? '上架房源' : '下架房源'}}</el-button>
                        <el-button type="text" @click="$refs.PublishListings.open('编辑房源', { ...row, label: row.label.split(',') }, 1)">编辑</el-button>
                        <el-button type="text" @click="$refs.operaRhHouseWindow.open('房源详情', {...row, seeType: 'desc'})">详情</el-button>
                        <el-button type="text" @click="deleteById(row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
        <!-- 详情 -->
        <OperaRhHouseWindow ref="operaRhHouseWindow" @success="handlePageChange"/>
        <!--    新建/修改    -->
        <PublishListings ref="PublishListings" @success="handlePageChange"/>
        <input type="file" @change="uploads" ref="getFile" accept=".xls" style="opacity: 0;" />
    </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  import OperaRhHouseWindow from '@/components/rentHouse/OperaRhHouseWindow'
  import PublishListings from '@/components/rentHouse/publishListings'
  import { updateById, importHouse } from '@/api/rentHouse/rhHouse'
  export default {
    name: 'myListings',
    extends: BaseTable,
    components: { TableLayout, Pagination, OperaRhHouseWindow, PublishListings },
    data () {
      return {
        // 搜索
        searchForm: {
          rentType: '',
          enableSell: '',
          phone: '',
          createUser: this.$store.state.userInfo.id
        }
      }
    },
    created () {
      this.config({
        module: '房源信息表',
        api: '/rentHouse/rhHouse',
        'field.id': 'id',
        'field.main': 'address',
        sorts: [{
          property: 'CREATE_TIME',
          direction: 'DESC'
        }]
      })
      this.search()
    },
    methods: {
      uploads(e) {
        const formdate = new FormData()
        formdate.append('file', e.target.files[0])
        importHouse(formdate)
          .then(res => {
            this.$refs.getFile.value = ''
            this.search()
          })
      },
      importHouse () {
        this.$refs.getFile.click()
      },
      offShelf (id, status) {
        this.$confirm(`${status === 1 ? '确定下架此房源吗?' : '确定上架此房源吗?'}`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          updateById({
            id,
            status: status === 1 ? 0 : '1'
          }).then(res => {
            this.$message.success('操作成功')
            this.search()
          })
        })
      },
      examine (type, id) {
        var reviewStatus = ''
        if (type === 1) {
          reviewStatus = 1
        } else {
          reviewStatus = 2
        }
        updateById({
          id,
          reviewStatus
        }).then(res => {
          this.$message.success('操作成功')
          this.search()
        })
      }
    }
  }
</script>
