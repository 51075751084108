<template>
  <GlobalWindow
    :title="title"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="类型名称" prop="typeName">
        <el-input v-model="form.typeName" placeholder="请输入类型名称" v-trim/>
      </el-form-item>
      <el-form-item label="语句集合" prop="statemenCollection">
        <div class="list">
          <div class="list_item" v-for="(item, index) in list" :key="index">
            <el-input v-model="item.text" placeholder="请输入常用语" v-trim/>
            <div style="width: 20px; height: 100%;"></div>
            <el-link type="primary" style="flex-shrink: 0;" :underline="false" @click="add" v-if="index === 0">添加</el-link>
            <el-link type="danger" style="flex-shrink: 0;" :underline="false" v-else @click="del(index)">删除</el-link>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  export default {
    name: 'OperaRhCommonPhrasesWindow',
    extends: BaseOpera,
    components: { GlobalWindow },
    data () {
      return {
        list: [
          { text: '' }
        ],
        // 表单数据
        form: {
          id: null,
          typeName: '',
          statemenCollection: ''
        },
        // 验证规则
        rules: {
          typeName: [
            { required: true, message: '请输入类型名称' }
          ]
        }
      }
    },
    created () {
      this.config({
        api: '/rentHouse/rhCommonPhrases',
        'field.id': 'id'
      })
    },
    methods: {
      open (title, target) {
        this.title = title
        this.list = [{ text: '' }]
        this.visible = true
        // 新建
        if (target == null) {
          this.$nextTick(() => {
            this.$refs.form.resetFields()
            this.form[this.configData['field.id']] = null
          })
          return
        }
        // 编辑
        this.$nextTick(() => {
          for (const key in this.form) {
            this.form[key] = target[key]
          }
          if (this.form.statemenCollection) {
            this.list = this.form.statemenCollection.split(',').map(item => {
              return {
                text: item
              }
            })
          }
        })
      },
      del(i) {
        this.list.splice(i, 1)
      },
      add() {
        this.list.push({ text: '' })
      },
      __confirmCreate () {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
          this.form.statemenCollection = this.list.map(item => item.text).join(',')
          // 调用新建接口
          this.isWorking = true
          this.api.create(this.form)
            .then(() => {
              this.visible = false
              this.$tip.apiSuccess('新建成功')
              this.$emit('success')
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking = false
            })
        })
      },
      __confirmEdit () {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
          // 调用更新接口
          this.isWorking = true
          this.form.statemenCollection = this.list.map(item => item.text).join(',')
          this.api.updateById(this.form)
            .then(() => {
              this.visible = false
              this.$tip.apiSuccess('修改成功')
              this.$emit('success')
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking = false
            })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    .list_item {
      width: 100%;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
    }
  }
</style>
