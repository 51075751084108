<template>
  <div>
    <el-upload
      :style="customStyle"
      :action="uploadImgUrl"
      :data="uploadData"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :on-error="uploadError"
      :before-upload="beforeAvatarUpload">
      <img v-if="file.imgurlfull" :src="file.imgurlfull" :style="customStyle" class="avatar">
      <div v-else :style="customStyle" style="display: flex; align-items: center; flex-direction: column; justify-content: center;">
        <i class="el-icon-plus avatar-uploader-icon"></i>
        <div class="tips-style">{{ tipsLabel }}</div>
      </div>
    </el-upload>
  </div>

</template>

<script>
export default {
  props: {
    file: {
      type: Object,
      default: () => {}
    },
    uploadData: Object,
    tipsLabel: {
      type: String,
      default: '请上传图片'
    },
    customStyle: {
      type: String,
      default: 'width: 140px; height: 140px;'
    }
  },
  data () {
    return {
      uploadImgUrl: process.env.VUE_APP_API_PREFIX + '/upload/image',
      // eslint-disable-next-line vue/no-dupe-keys
      imgurlfull: ''
    }
  },

  methods: {
    // 上传图片
    handleAvatarSuccess (res, file) {
      console.log(res)
      if (res.code === 200) {
        this.file.imgurl = res.data.accessUri
        this.file.imgurlfull = res.data.accessUri
        this.$message.success('上传成功')
        this.$emit('uploadSuccess', { imgurl: res.data.accessUri, imgurlfull: process.env.VUE_APP_API_IMG + res.data.accessUri })
      } else {
        this.$message.error('上传失败')
      }
      this.$emit('uploadEnd')
    },
    uploadError () {
      this.$message.error('上传失败')
      this.$emit('endUpload')
    },
    // // 拦截
    beforeAvatarUpload (file) {
      this.$emit('uploadBegin')
      return true
    }
  }
}
</script>

<style lang="scss" scoped>
$image-width: 100px;
::v-deep .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  /*line-height: 60px;*/
  text-align: center;
  margin-bottom: 10px;
}
.avatar {
  display: block;
}
.tips-style {
  height: 13px;
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 13px;
}
</style>
