import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/pc/rhChat/getChatList', data, {
    trim: true
  })
}

// 发送消息
export function send (data) {
  return request.post('/pc/rhChat/sendMsg', data, {
    trim: true
  })
}

// 聊天内容
export function page (data) {
  return request.post('/pc/rhChatLog/page', data, {
    trim: true
  })
}

// 读取消息
export function readMsg (chatId) {
  return request.get(`/pc/rhChat/readMsg?chatId=${chatId}`)
}

// 刷新用户所在聊天室页面
export function flushChatWeb (chatId, type) {
  return request.get(`/pc/rhChat/flushChatWeb?chatId=${chatId}&type=${type}`)
}


