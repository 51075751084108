import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/pc/rhComment/page', data, {
    trim: true
  })
}

// 删除
export function deleteById (id) {
  return request.get(`/pc/rhComment/delete/${id}`)
}

// 批量删除
export function deleteByIdInBatch (ids) {
  return request.get('/pc/rhComment/delete/batch', {
    params: {
      ids
    }
  })
}
