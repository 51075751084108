<template>
    <GlobalWindow
        :title="title"
        width="600px"
        :visible.sync="visible"
        :withFooter="false"
        :confirm-working="isWorking"
        @confirm="confirm"
    >
        <div class="list">
            <div class="list_item">
                <div class="list_item_label">房屋类型：</div>
                <div class="list_item_val" v-if="form.enableSell === 0">租房</div>
                <div class="list_item_val" v-if="form.enableSell === 1">售房</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">城市：</div>
                <div class="list_item_val">{{form.cityName}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">我是：</div>
                <div class="list_item_val" v-if="form.rentType === 0">业主</div>
                <div class="list_item_val" v-if="form.rentType === 1">室友</div>
                <div class="list_item_val" v-if="form.rentType === 2">转租</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源标题：</div>
                <div class="list_item_val">{{form.title}}{{form.bedroomNum}}房{{form.saloonNum}}厅{{form.toiletNum}}卫</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">租房类型：</div>
                <div class="list_item_val">{{form.houseTypeName}}</div>
            </div>
            <div class="list_item" v-if="form.enableSell === 0 && form.rentType === 2">
                <div class="list_item_label">合同期限：</div>
                <div class="list_item_val">{{form.term}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">装修情况：</div>
                <div class="list_item_val" v-if="form.decorationLevel === 0">精装</div>
                <div class="list_item_val" v-if="form.decorationLevel === 1">简装</div>
                <div class="list_item_val" v-if="form.decorationLevel === 2">豪装</div>
                <div class="list_item_val" v-if="form.decorationLevel === 3">毛坯</div>
                <div class="list_item_val" v-if="form.decorationLevel === 4">不限</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">出租方式：</div>
                <div class="list_item_val">{{form.rentalFormName}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">户型：</div>
                <div class="list_item_val">{{form.bedroomNum}}房{{form.saloonNum}}厅{{form.toiletNum}}卫</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">面积：</div>
                <div class="list_item_val">{{form.area}}㎡</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">租金：</div>
                <div class="list_item_val">{{form.price}}元</div>
            </div>
            <div class="list_item" v-if="form.sellPrice">
                <div class="list_item_label">售价：</div>
                <div class="list_item_val">{{form.sellPrice}}万元</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">入住时间：</div>
                <div class="list_item_val">{{form.inTime ? form.inTime : '随时入住'}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">楼层：</div>
                <div class="list_item_val">{{form.inFloorNum}}层 共{{form.floorNum}}层 {{form.housePassType}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源特色：</div>
                <div class="list_item_val">{{form.description}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源设备：</div>
                <div class="list_item_list" v-if="form.label">
                    <el-tag style="margin-right: 15px;margin-bottom: 10px;" v-for="(item, index) in form.houseDeviceNames" :key="index">{{item}}</el-tag>
                </div>
            </div>
            <div class="list_item">
                <div class="list_item_label">图片：</div>
                <div class="list_item_list" v-if="form.pic">
                    <div class="list_item_list_image" v-for="(item, index) in form.pic.split(',')" :key="index">
                        <el-image
                            style="width: 100%; height: 100%;"
                            :src="baseUrl + item"
                            :preview-src-list="[baseUrl + item]">
                        </el-image>
                    </div>
                </div>
            </div>
            <div class="list_item">
                <div class="list_item_label">视频：</div>
                <div class="list_item_list" v-if="form.video">
                    <video controls :src="baseUrl + form.video"></video>
                </div>
                <div class="list_item_list" v-else>
                    暂未上传
                </div>
            </div>
            <div class="list_item">
                <div class="list_item_label">房源标签：</div>
                <div class="list_item_list" v-if="form.label">
                    <el-tag style="margin-right: 15px;margin-bottom: 10px;" v-for="(item, index) in form.label.split(',')" :key="index">{{item}}</el-tag>
                </div>
            </div>
            <div class="list_item">
                <div class="list_item_label">手机号：</div>
                <div class="list_item_val">{{form.phone ? form.phone : '暂未填写'}}</div>
            </div>
            <div class="list_item">
                <div class="list_item_label">公开联系方式：</div>
                <div class="list_item_val">{{form.publicContact === 1 ? '公开' : '不公开'}}</div>
            </div>
            <div class="list_item" style="display: flex; align-items: center; justify-content: center;" v-if="form.seeType !== 'desc'">
                <el-button type="success" :loading="isWorking" @click="examine(1)">通过</el-button>
                <el-button type="danger" :loading="isWorking" @click="examine(2)">不通过</el-button>
            </div>
        </div>
    </GlobalWindow>
</template>

<script>
import BaseOpera from '@/components/base/BaseOpera'
import GlobalWindow from '@/components/common/GlobalWindow'
import { updateById } from '@/api/rentHouse/rhHouse'
export default {
  name: 'OperaRhHouseWindow',
  extends: BaseOpera,
  components: { GlobalWindow },
  data () {
    return {
      baseUrl: process.env.VUE_APP_API_IMG,
      form: {
        id: null,
        cityName: '',
        rentType: '',
        title: '',
        enableShortRent: '',
        address: '',
        houseType: '',
        decorationLevel: '',
        rentalFormName: '',
        bedroomNum: '',
        saloonNum: '',
        toiletNum: '',
        area: '',
        enableSell: '',
        inTime: '',
        inFloorNum: '',
        floorNum: '',
        liftNum: '',
        haveMetro: '',
        description: '',
        pic: '',
        video: '',
        displayMonth: '',
        label: '',
        price: '',
        mobile: '',
        phone: '',
        wx: '',
        publicContact: '',
        seeType: '',
        authPic: '',
        idCardFront: '',
        idCardOpposite: '',
        buildingNum: '',
        roomNum: '',
        buildingNumUnit: '',
        roomNumUnit: '',
        sellPrice: '',
        housePassType: '',
        houseDeviceNames: ''
      }
    }
  },
  created () {
    this.config({
      api: '/rentHouse/rhHouse',
      'field.id': 'id'
    })
  },
  methods: {
    examine (type) {
      var that = this;
      if (type === 2) {
        this.$prompt('请输入原因', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        }).then(({ value }) => {
          that.isWorking = true
          updateById({
            id: that.form.id,
            reviewStatus: type,
            rejectReason: value
          }).then(res => {
            that.$tip.apiSuccess('审核成功')
            that.$emit('success')
            that.visible = false
          }).finally(() => {
            that.isWorking = false
          })
        }).catch(() => {

        });
      } else {
        that.isWorking = true
        updateById({
          id: that.form.id,
          reviewStatus: type,
          rejectReason: ''
        }).then(res => {
          that.$tip.apiSuccess('审核成功')
          that.$emit('success')
          that.visible = false
        }).finally(() => {
          that.isWorking = false
        })
      }
    }
  }
}
</script>
<style>
    .el-image-viewer__wrapper {
        z-index: 2005 !important;
    }
</style>
<style lang="scss" scoped>
    .list {
        width: 100%;
        display: flex;
        flex-direction: column;
        .list_item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            margin-bottom: 20px;
            .list_item_label {
                width: 120px;
                flex-shrink: 0;
                font-size: 16px;
                color: #000000;
                text-align: right;
            }
            .list_item_list {
                flex: 1;
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                video {
                    width: 100%;
                    height: 200px;
                }
                .list_item_list_image {
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    margin-left: 15px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .list_item_val {
                flex: 1;
                font-size: 16px;
                color: #000000;
            }
        }
    }
</style>
