<template>
    <TableLayout :permissions="['renthouse:rhservicemember:query']">
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <ul class="toolbar" v-permissions="['renthouse:rhservicemember:create']">
                <li><el-button type="primary" @click="$refs.operaRhServiceMemberWindow.open('新建增值会员服务')" icon="el-icon-plus" v-permissions="['renthouse:rhservicemember:create']">新建</el-button></li>
            </ul>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
            >
                <el-table-column prop="memberName" label="会员的名称" min-width="100px"></el-table-column>
                <el-table-column prop="memberType" label="会员类型" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.memberType === 0">增值服务</span>
                        <span v-if="row.memberType === 1">代答服务</span>
                    </template>
                </el-table-column>
                <el-table-column prop="firstMonthPrice" label="首次月卡价格" min-width="100px"></el-table-column>
                <el-table-column prop="firstQuarterPrice" label="首次季卡价格" min-width="100px"></el-table-column>
                <el-table-column prop="firstYearPrice" label="首次年卡价格" min-width="100px"></el-table-column>
                <el-table-column prop="monthPrice" label="月卡价格" min-width="100px"></el-table-column>
                <el-table-column prop="quarterPrice" label="季卡价格" min-width="100px"></el-table-column>
                <el-table-column prop="yearPrice" label="年卡价格" min-width="100px"></el-table-column>
                <el-table-column prop="firstMemberPrice" label="首次会员价格" min-width="100px"></el-table-column>
                <el-table-column prop="memberPrice" label="会员价格" min-width="100px"></el-table-column>
                <el-table-column prop="firstNoMemberPrice" label="首次非会员价格" min-width="120px"></el-table-column>
                <el-table-column prop="noMemberPrice" label="非会员价格" min-width="100px"></el-table-column>
                <el-table-column prop="createTime" label="创建时间" min-width="150px"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间" min-width="150px"></el-table-column>
                <el-table-column
                    v-if="containPermissions(['renthouse:rhservicemember:update', 'renthouse:rhservicemember:delete'])"
                    label="操作"
                    min-width="120"
                    fixed="right"
                >
                    <template slot-scope="{row}">
                        <el-button type="text" @click="$refs.operaRhServiceMemberWindow.open('编辑增值会员服务', row)" icon="el-icon-edit" v-permissions="['renthouse:rhservicemember:update']">编辑</el-button>
                        <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['renthouse:rhservicemember:delete']">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
        <!-- 新建/修改 -->
        <OperaRhServiceMemberWindow ref="operaRhServiceMemberWindow" @success="handlePageChange"/>
    </TableLayout>
</template>

<script>
    import BaseTable from '@/components/base/BaseTable'
    import TableLayout from '@/layouts/TableLayout'
    import Pagination from '@/components/common/Pagination'
    import OperaRhServiceMemberWindow from '@/components/rentHouse/OperaRhServiceMemberWindow'
    export default {
        name: 'RhServiceMember',
        extends: BaseTable,
        components: { TableLayout, Pagination, OperaRhServiceMemberWindow },
        data () {
            return {
                // 搜索
                searchForm: {
                }
            }
        },
        created () {
            this.config({
                module: '增值会员服务',
                api: '/rentHouse/rhServiceMember',
                'field.id': 'id',
                'field.main': 'id'
            })
            this.search()
        }
    }
</script>
