<template>
  <GlobalWindow
    title="手动执行任务"
    :visible.sync="visible"
    :confirm-working="isWorking"
    confirm-text="立即执行"
    cancel-text="关闭"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <template v-if="job != null">
        <el-form-item label="任务名称" prop="jobName">
          {{job.jobName}}
        </el-form-item>
        <el-form-item label="任务处理器" prop="handler">
          {{job.handler}}
        </el-form-item>
      </template>
      <el-form-item label="业务时间" prop="businessTime" required>
        <el-date-picker
          v-model="form.businessTime"
          :clearable="false"
          type="datetime"
          placeholder="请选择业务时间"
          value-format="yyyy-MM-dd HH:mm:ss"
         >
        </el-date-picker>
      </el-form-item>
    </el-form>
  </GlobalWindow>
</template>

<script>
import dayjs from 'dayjs'
import GlobalWindow from '@/components/common/GlobalWindow'
import { trigger } from '@/api/system/job'

export default {
  name: 'TriggerJobWindow',
  components: { GlobalWindow },
  data () {
    return {
      visible: false,
      isWorking: false,
      // 表单数据
      form: {
        id: null,
        businessTime: null
      },
      // 任务数据
      job: null,
      // 验证规则
      rules: {
        businessTime: [
          { required: true, message: '请选择业务时间' }
        ]
      }
    }
  },
  methods: {
    /**
     * 打开窗口
     *
     * @param row 行对象
     */
    open (row) {
      this.visible = true
      this.form.id = row.id
      this.form.businessTime = dayjs().format('YYYY-MM-DD HH:mm:ss')
      this.job = row
    },
    /**
     * 确认执行
     */
    confirm () {
      this.isWorking = true
      trigger(this.form)
        .then(() => {
          this.$tip.apiSuccess('任务已启动，如任务启用了日志，可在任务日志中查看执行进度。')
          this.visible = false
        })
        .catch(e => {
          this.$tip.apiFailed(e)
        })
        .finally(() => {
          this.isWorking = false
        })
    }
  }
}
</script>
