<template>
    <TableLayout :permissions="['renthouse:rhmember:query']">
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <ul class="toolbar" v-permissions="['renthouse:rhmember:create', 'renthouse:rhmember:delete']">
<!--                <li><el-button type="primary" @click="$refs.operaRhMemberWindow.open('新建会员信息')" icon="el-icon-plus" v-permissions="['renthouse:rhmember:create']">新建</el-button></li>-->
<!--                <li><el-button @click="deleteByIdInBatch" icon="el-icon-delete" v-permissions="['renthouse:rhmember:delete']">删除</el-button></li>-->
            </ul>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                border
            >
                <el-table-column prop="memberName" label="会员名称" min-width="120px"></el-table-column>
                <el-table-column label="发起联系数量" min-width="100px">
                    <template slot-scope="{row}">
                        <span>{{row.requestNum}}次</span>
                    </template>
                </el-table-column>
                <el-table-column label="刷新房源间隔h/次" min-width="120px">
                    <template slot-scope="{row}">
                        <span>{{row.refreshInterval}}h</span>
                    </template>
                </el-table-column>
                <el-table-column label="服务信息" min-width="150px">
                    <template slot-scope="{row}">
                        <span v-if="row.serviceInfo === 1">是</span>
                        <span v-if="row.serviceInfo === 0">否</span>
                    </template>
                </el-table-column>
                <el-table-column label="查看谁收藏我的房源" min-width="150px">
                    <template slot-scope="{row}">
                        <span v-if="row.getFollowHouse === 1">是</span>
                        <span v-if="row.getFollowHouse === 0">否</span>
                    </template>
                </el-table-column>
                <el-table-column label="查看浏览信息" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.getViewHouse === 1">是</span>
                        <span v-if="row.getViewHouse === 0">否</span>
                    </template>
                </el-table-column>
                <el-table-column label="电子签约" min-width="100px">
                    <template slot-scope="{row}">
                        <span v-if="row.esign === 1">是</span>
                        <span v-if="row.esign === 0">否</span>
                    </template>
                </el-table-column>
                <el-table-column label="管理服务房源" min-width="130px">
                    <template slot-scope="{row}">
                        <span v-if="row.manageHouse === 1">是</span>
                        <span v-if="row.manageHouse === 0">否</span>
                    </template>
                </el-table-column>
                <el-table-column label="上架数量" min-width="100px">
                    <template slot-scope="{row}">
                        <span>{{row.rentNum}}套</span>
                    </template>
                </el-table-column>
                <el-table-column label="售卖数量" min-width="100px">
                    <template slot-scope="{row}">
                        <span>{{row.salesNum}}套</span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="containPermissions(['renthouse:rhmember:update'])"
                    label="操作"
                    min-width="180"
                    fixed="right"
                >
                    <template slot-scope="{row}">
                        <el-button type="text" style="margin-right: 15px;" @click="$refs.operaRhMemberWindow.open('编辑会员信息', row)" icon="el-icon-edit" v-permissions="['renthouse:rhmember:update']">编辑</el-button>
                        <el-popover
                            placement="right"
                            width="180"
                            trigger="click">
                            <div style="width: 100%; display: flex; flex-direction: column; flex-wrap: wrap;">
                                <span>月卡：{{!row.monthPrice >= 0 ? row.monthPrice+'元' : '无'}}</span>
                                <span>月卡(首次)：{{row.firstMonthPrice >= 0 ? row.firstMonthPrice+'元' : '无'}}</span>
                                <span>季卡：{{row.quarterPrice >= 0 ? row.quarterPrice+'元' : '无'}}</span>
                                <span>季卡(首次)：{{row.firstQuarterPrice >= 0 ? row.firstQuarterPrice+'元' : '无'}}</span>
                                <span>年卡：{{row.yearPrice >= 0 ? row.yearPrice+'元' : '无'}}</span>
                                <span>年卡(首次)：{{row.firstYearPrice >= 0 ? row.firstYearPrice+'元' : '无'}}</span>
                            </div>
                            <el-button type="text" icon="el-icon-view" slot="reference">查看价格</el-button>
                        </el-popover>
<!--                        <el-button type="text" @click="deleteById(row)" icon="el-icon-delete" v-permissions="['pc:rhmember:delete']">删除</el-button>-->
                    </template>
                </el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
        <!-- 新建/修改 -->
        <OperaRhMemberWindow ref="operaRhMemberWindow" @success="handlePageChange"/>
    </TableLayout>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import TableLayout from '@/layouts/TableLayout'
import Pagination from '@/components/common/Pagination'
import OperaRhMemberWindow from '@/components/rentHouse/OperaRhMemberWindow'
export default {
  name: 'RhMember',
  extends: BaseTable,
  components: { TableLayout, Pagination, OperaRhMemberWindow },
  data () {
    return {
      // 搜索
      searchForm: {
      }
    }
  },
  created () {
    this.config({
      module: '会员信息表',
      api: '/rentHouse/rhMember',
      'field.id': 'id',
      'field.main': 'id'
    })
    this.search()
  }
}
</script>
