<template>
    <GlobalWindow
        :title="title"
        :visible.sync="visible"
        :confirm-working="isWorking"
        @confirm="confirm"
    >
        <el-form :model="form" ref="form" :rules="rules">
            <el-form-item label="服务的码值" prop="serviceCode">
                <el-input v-model="form.serviceCode" placeholder="请输入服务的码值" v-trim/>
            </el-form-item>
            <el-form-item label="服务名称" prop="serviceName">
                <el-input v-model="form.serviceName" placeholder="请输入服务名称" v-trim/>
            </el-form-item>
        </el-form>
    </GlobalWindow>
</template>

<script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  export default {
    name: 'OperaRhServiceCategoriesWindow',
    extends: BaseOpera,
    components: { GlobalWindow },
    data () {
      return {
        // 表单数据
        form: {
          id: null,
          serviceCode: '',
          serviceName: ''
        },
        // 验证规则
        rules: {
          serviceCode: [
            { required: true, message: '请输入服务的码值' }
          ],
          serviceName: [
            { required: true, message: '请输入服务名称' }
          ]
        }
      }
    },
    created () {
      this.config({
        api: '/rentHouse/rhServiceCategories',
        'field.id': 'id'
      })
    }
  }
</script>
