<template>
  <GlobalWindow
    :title="title"
    width="50%"
    :visible.sync="visible"
    :confirm-working="isWorking"
    @confirm="confirm"
  >
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="城市" prop="cityId">
        <el-select v-model="form.cityId" placeholder="请选择">
          <el-option
            v-for="item in cityList"
            :key="item.id"
            :label="item.cityName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="小区位置" prop="address">
        <span style="font-size: 13px; color: black;" v-if="form.address">{{form.address}}</span>
        <el-button @click="$refs.ref.openDialog()">选择位置</el-button>
      </el-form-item>
      <el-form-item label="房屋类型" prop="houseType">
        <el-select v-model="form.houseType" placeholder="请选择">
          <el-option
            v-for="item in types"
            :key="item.id"
            :label="item.label"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="装修情况" prop="decorationLevel">
        <el-select v-model="form.decorationLevel" placeholder="请选择">
          <el-option
            v-for="item in renovation"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="房屋面积" prop="roomArea">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.roomArea" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">㎡</span>
        </div>
      </el-form-item>
      <el-form-item label="户型" prop="huxing">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.bedroomNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">房</span>
          <el-input v-model="form.saloonNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">厅</span>
          <el-input v-model="form.toiletNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">卫</span>
        </div>
      </el-form-item>
      <el-form-item label="楼层" prop="louceng">
        <div style="width: 100%; display: flex; align-items: center;">
          <el-input v-model="form.inFloorNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">层</span>
          <span style="margin: 0 10px; font-size: 13px; color: black;">共</span>
          <el-input v-model="form.floorNum" type="number" placeholder="请输入" />
          <span style="margin: 0 10px; font-size: 13px; color: black;">层</span>
          <el-select v-model="form.housePassType" style="width: 300px;" placeholder="请选择">
            <el-option
              v-for="item in elevator"
              :key="item.label"
              :label="item.label"
              :value="item.label">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="特惠售价" prop="specialOffer">
        <div style="width: 50%; display: flex; align-items: center;">
          <el-input v-model="form.specialOffer" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">万</span>
        </div>
      </el-form-item>
      <el-form-item label="市场价" prop="marketPrice">
        <div style="width: 50%; display: flex; align-items: center;">
          <el-input v-model="form.marketPrice" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">万</span>
        </div>
      </el-form-item>
      <el-form-item label="评估价" prop="evaluationPrice">
        <div style="width: 50%; display: flex; align-items: center;">
          <el-input v-model="form.evaluationPrice" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">万</span>
        </div>
      </el-form-item>
      <el-form-item label="最高贷款额度" prop="maxLoanAmount">
        <div style="width: 50%; display: flex; align-items: center;">
          <el-input v-model="form.maxLoanAmount" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">万</span>
        </div>
      </el-form-item>
      <el-form-item label="特患贷款利率" prop="preLoanInterestRate">
        <div style="width: 50%; display: flex; align-items: center;">
          <el-input v-model="form.preLoanInterestRate" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">%</span>
        </div>
      </el-form-item>
      <el-form-item label="月供还款" prop="monthlyRepayment">
        <div style="width: 50%; display: flex; align-items: center;">
          <el-input v-model="form.monthlyRepayment" type="number" placeholder="请输入"></el-input>
          <span style="margin-left: 10px; font-size: 13px; color: black;">元</span>
        </div>
      </el-form-item>
      <el-form-item label="房源描述" prop="description">
        <el-input
          type="textarea"
          placeholder="写下你想说的，（地点优势，装修，配套显示房屋亮点）如交通信息，房源周边商圈，小区环境，家电家具设备，对租客的期待，请避免此处写联系方式引发骚扰，以上描述会影响你的房租金，你可得好好写哦。。。"
          v-model="form.description"
          rows="7"
        />
      </el-form-item>
      <el-form-item label="上传图片" prop="pic">
        <span style="font-size: 13px; color: #8c939d;">建议选择横版图片，图片建议6张，首张会作为封面图片</span>
        <el-upload
          :action="base"
          :limit="6"
          :before-upload="beforeUpluad"
          :on-exceed="onExceed"
          :file-list="fileList"
          list-type="picture-card"
          :on-success="onSuccess"
          :on-remove="handleRemove">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传视频" prop="video">
        <span style="font-size: 13px; color: #8c939d;">可选，视频时长不超过 60 秒</span>
        <el-upload
          accept=".mp4"
          :action="base1"
          :limit="1"
          :before-upload="beforeUpluad1"
          :on-exceed="onExceed1"
          :file-list="videoList"
          list-type="picture-card"
          :on-success="onSuccess1"
          :on-remove="handleRemove1">
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="手机号" prop="phone">
        <el-input v-model="form.phone" maxlength="11" type="number" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="是否公开联系方式" prop="publicContact">
        <el-switch
          v-model="form.publicContact"
          active-color="#13ce66"
          inactive-color="#ff4949"
          :active-value="1"
          :inactive-value="0">
        </el-switch>
      </el-form-item>
    </el-form>
    <!-- 地图选点 -->
    <mapSelect @chooseOrgAddr="chooseOrgAddr" ref="ref"></mapSelect>
  </GlobalWindow>
</template>

<script>
  import BaseOpera from '@/components/base/BaseOpera'
  import GlobalWindow from '@/components/common/GlobalWindow'
  import mapSelect from '@/components/common/map'
  import { fetchList } from '@/api/rentHouse/rhCityInfo'
  import { findByDictCode, getSubwayByTx } from '@/api/system/common'

  export default {
    name: 'OperaSpecialOfferWindow',
    extends: BaseOpera,
    components: { GlobalWindow, mapSelect },
    data () {
      const huxing = (rule, value, callback) => {
        if (!this.form.bedroomNum || !this.form.saloonNum || !this.form.toiletNum) {
          callback(new Error('请输入完整的户型信息'));
        } else {
          callback();
        }
      };
      const jiagezu = (rule, value, callback) => {
        if (!this.form.price || !this.form.payCycle) {
          callback(new Error('请输入完整的信息'));
        } else {
          callback();
        }
      };
      const jiageshou = (rule, value, callback) => {
        if (!this.form.sellPrice || !this.form.buyPayStyle) {
          callback(new Error('请输入完整的信息'));
        } else {
          callback();
        }
      };
      const louceng = (rule, value, callback) => {
        if (!this.form.inFloorNum || !this.form.floorNum || !this.form.housePassType) {
          callback(new Error('请输入完整的信息'));
        } else {
          callback();
        }
      };
      return {
        base: process.env.VUE_APP_API_PREFIX + '/upload/image',
        base1: process.env.VUE_APP_API_PREFIX + '/upload/attach',
        city: [],
        types: [],
        elevator: [],
        device: [],
        labels: [],
        renovation: [
          { name: '精装', id: 0 },
          { name: '简装', id: 1 },
          { name: '豪装', id: 2 },
          { name: '毛坯', id: 3 },
          { name: '不限', id: 4 }
        ],
        // 表单数据
        form: {
          id: null,
          cityId: '',
          title: '',
          address: '',
          latitude: '',
          longitude: '',
          houseType: '',
          decorationLevel: '',
          roomArea: '',
          bedroomNum: '',
          saloonNum: '',
          toiletNum: '',
          inFloorNum: '',
          floorNum: '',
          housePassType: '',
          description: '',
          pic: '',
          video: '',
          replyStyle: '0',
          phone: '',
          publicContact: 0,
          recentMetro: '',

          specialOffer: '',
          marketPrice: '',
          evaluationPrice: '',
          maxLoanAmount: '',
          preLoanInterestRate: '',
          monthlyRepayment: '',

          reviewStatus: 1,
          status: 1,
          houseCategory: 2
        },
        fileList: [],
        videoList: [],
        // 验证规则
        rules: {
          specialOffer: [
            { required: true, message: '请输入特惠售价', trigger: 'blur' }
          ],
          marketPrice: [
            { required: true, message: '请输入市场价', trigger: 'blur' }
          ],
          evaluationPrice: [
            { required: true, message: '请输入评估价', trigger: 'blur' }
          ],
          maxLoanAmount: [
            { required: true, message: '请输入最高贷款额度', trigger: 'blur' }
          ],
          preLoanInterestRate: [
            { required: true, message: '请输入特患贷款利率', trigger: 'blur' }
          ],
          monthlyRepayment: [
            { required: true, message: '请输入月供还款', trigger: 'blur' }
          ],
          roomArea: [
            { required: true, message: '请输入房屋面积', trigger: 'blur' }
          ],
          cityId: [
            { required: true, message: '请选择城市', trigger: 'blur' }
          ],
          address: [
            { required: true, message: '请选择小区位置', trigger: 'blur' }
          ],
          houseType: [
            { required: true, message: '请选择房屋类型', trigger: 'blur' }
          ],
          decorationLevel: [
            { required: true, message: '请选择装修情况', trigger: 'blur' }
          ],
          huxing: [
            { required: true, validator: huxing, trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入手机号', trigger: 'blur' }
          ],
          pic: [
            { required: true, message: '请上传图片', trigger: 'blur' }
          ],
          description: [
            { required: true, message: '请输入房源特色', trigger: 'blur' }
          ],
          jiagezu: [
            { required: true, validator: jiagezu, trigger: 'blur' }
          ],
          shou: [
            { required: true, validator: jiageshou, trigger: 'blur' }
          ],
          louceng: [
            { required: true, validator: louceng, trigger: 'blur' }
          ]
        },
        cityList: []
      }
    },
    created () {
      this.config({
        api: '/rentHouse/rhHouse',
        'field.id': 'id'
      })
    },
    methods: {
      open (title, target) {
        this.title = title
        this.fileList = []
        this.videoList = []
        this.visible = true
        this.getCity()
        this.getfindByDictCode()
        // 新建
        if (target == null) {
          this.$nextTick(() => {
            this.$refs.form.resetFields()
            this.form[this.configData['field.id']] = null
          })
          return
        }
        // 编辑
        this.$nextTick(() => {
          for (const key in this.form) {
            this.form[key] = target[key]
          }
          let arr = this.form.pic.split(',')
          arr.forEach(item => {
            this.fileList.push({ url: process.env.VUE_APP_API_IMG + item })
          })
          if (this.form.room) {
            this.room = this.form.room.split(' ')
          }
        })
      },
      __confirmCreate () {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
          // 调用新建接口
          this.isWorking = true
          this.api.create(this.form)
            .then(() => {
              this.visible = false
              this.$tip.apiSuccess('新建成功')
              this.$emit('success')
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking = false
            })
        })
      },
      __confirmEdit () {
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return
          }
          // 调用更新接口
          this.isWorking = true
          this.api.updateById(this.form)
            .then(() => {
              this.visible = false
              this.$tip.apiSuccess('修改成功')
              this.$emit('success')
            })
            .catch(e => {
              this.$tip.apiFailed(e)
            })
            .finally(() => {
              this.isWorking = false
            })
        })
      },
      onSuccess(res) {
        this.fileList.push({ ...res.data, url: process.env.VUE_APP_API_IMG + res.data.accessUri })
        let arr = []
        this.fileList.forEach(item => {
          arr.push(item.accessUri)
        })
        this.form.pic = arr.join(',')
      },
      onSuccess1(res) {
        this.videoList.push({ ...res.data, url: process.env.VUE_APP_API_IMG + res.data.accessUri })
        this.form.video = res.data.accessUri
      },
      handleRemove(a, b) {
        this.fileList.forEach((item, index) => {
          if (item.accessUri === a.accessUri) {
            this.fileList.splice(index, 1)
          }
        })
        let arr = b.map(item => item.accessUri)
        this.form.pic = arr.join(',')
      },
      handleRemove1(a, b) {
        this.videoList = []
        this.form.video = ''
      },
      beforeUpluad1(file) {
        if ((file.size / 1024 / 1024) > 50) {
          this.$message.warning('上传视频大小不能超过50mb')
          return false
        }
        return true
      },
      beforeUpluad(file) {
        if ((file.size / 1024 / 1024) > 5) {
          this.$message.warning('上传图片大小不能超过5mb')
          return false
        }
        return true
      },
      onExceed() {
        this.$message.warning('最多只能上传6张图片')
      },
      onExceed1() {
        this.$message.warning('最多只能上传1个视频')
      },
      getCity() {
        fetchList({
          capacity: 9999,
          page: 1,
          model: {
            display: 1
          }
        }).then(res => {
          this.cityList = res.records
        })
      },
      // 选取地理位置后的回调
      chooseOrgAddr(data){
        this.form.address = data.address
        this.form.title = data.name
        this.form.latitude = data.lat
        this.form.longitude = data.lng
        getSubwayByTx(`nearby(${data.lat},${data.lng},1000,1)`)
          .then(res => {
            let data = JSON.parse(res)
            if (data.status === 0) {
              let arr = []
              data.data.forEach(item => {
                if (item._distance <= 3000) {
                  arr.push(item.title)
                }
              })
              this.form.recentMetro = arr.join(',')
              console.log(this.form.recentMetro)
            }
          })
        this.$refs.ref.closeDialog()
      },
      getfindByDictCode() {
        // 房屋类型
        findByDictCode({
          dictCode: 'sys_house_type'
        }).then(res => {
          if (res.length > 0) {
            this.types = res
          }
        })
        // 电梯
        findByDictCode({
          dictCode: 'sys_house_dianti'
        }).then(res => {
          if (res.length > 0) {
            this.elevator = res
            this.form.housePassType = res[0].label
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  #map {
    width: 100%;
    height: 300px;
    background: #8c939d;
  }
</style>
