import request from '../../utils/request'

// 查询
export function fetchList (data) {
  return request.post('/renthouse/rhHouseDeviceConfig/page', data, {
    trim: true
  })
}

// 创建
export function create (data) {
  return request.post('/renthouse/rhHouseDeviceConfig/create', data)
}

// 修改
export function updateById (data) {
  return request.post('/renthouse/rhHouseDeviceConfig/updateById', data)
}

// 删除
export function deleteById (id) {
  return request.get(`/renthouse/rhHouseDeviceConfig/delete/${id}`)
}
