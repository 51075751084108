<template>
    <TableLayout :permissions="['renthouse:invite:query']">
        <!-- 搜索表单 -->
        <el-form ref="searchForm" slot="search-form" :model="searchForm" label-width="100px" inline>
            <el-form-item label="用户名" prop="startUser">
                <el-input v-model="searchForm.startUser" placeholder="请输入用户名" @keypress.enter.native="search"></el-input>
            </el-form-item>
            <section>
                <el-button type="primary" @click="search">搜索</el-button>
                <el-button @click="reset">重置</el-button>
            </section>
        </el-form>
        <!-- 表格和分页 -->
        <template v-slot:table-wrap>
            <el-table
                v-loading="isWorking.search"
                :data="tableData.list"
                stripe
            >
                <el-table-column prop="userName" label="用户名" min-width="100px"></el-table-column>
                <el-table-column prop="totalPoints" label="总积分" min-width="100px"></el-table-column>
                <el-table-column prop="totalAmount" label="总金额" min-width="100px"></el-table-column>
            </el-table>
            <pagination
                @size-change="handleSizeChange"
                @current-change="handlePageChange"
                :pagination="tableData.pagination"
            >
            </pagination>
        </template>
    </TableLayout>
</template>

<script>
  import BaseTable from '@/components/base/BaseTable'
  import TableLayout from '@/layouts/TableLayout'
  import Pagination from '@/components/common/Pagination'
  export default {
    name: 'invite',
    extends: BaseTable,
    components: { TableLayout, Pagination },
    data () {
      return {
        // 搜索
        searchForm: {
          startUser: '',
          targetUser: ''
        }
      }
    },
    created () {
      this.config({
        module: '用户积分记录',
        api: '/rentHouse/rhUserCode',
        'field.id': 'id',
        'field.main': 'id'
      })
      this.search()
    }
  }
</script>
